.gamebox_body {
    height: 15.2rem;
    background: url("~@images/gamebox/bg.png") no-repeat center;
    background-size: cover;
    position: relative;
    box-sizing: border-box;
    .title {
        position: absolute;
        top: 3rem;
        width: 100%;
        box-sizing: border-box;
        padding: .5rem;
    }
    .content {
        margin: 0 .35rem;
        padding: .4rem .25rem;
        border-radius: .15rem;

        position: absolute;
        top: 5rem;
        
        color: #eee;
        border: #EDABA0 solid 1px;
        background-color: rgba($color:#171740, $alpha:.8);

        h2 {
            color: #FDF2C2;
            margin-bottom: .2rem;
        }

        p {
            font-weight: 300;
            font-size: .26rem;
            line-height: .55rem;
            text-indent: .55rem;
        }
    }
}