$bg1_height: 12rem;
$content_size: .24rem;
$wenshu_title_size: 1rem;

.wallet_body {
    position: relative;
    background-color: #131343;
    .wallet_bg_1 {
        position: absolute;
        top: -.4rem;
        left: 0;
        width: 100%;
        height: $bg1_height;
        background-image: url('~@images/wallet/bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 50%;
    }

    .wallet_bg_2 {
        position: absolute;
        top: 9rem;
        left: 0;
        width: 100%;
        height: $bg1_height;
        // background-image: url('~@images/wallet/bg_2.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 50%;
    }

    .wallet_bg_3 {
        position: absolute;
        bottom: -8.4rem;
        left: 0;
        width: 100%;
        height: $bg1_height / 1.27;
        background-image: url('~@images/wallet/bg_3.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 50%;
    }

    box-sizing: border-box;
    padding: .32rem;

    .wallet_title {
        height: 1.65rem;
        background-image: url('~@images/wallet/title.png');
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: .4rem;
    }

    .content {
        padding: .35rem .2rem .3rem;
        border-radius: .15rem;
        border: #3B65F1 solid 1px;
        backdrop-filter: blur(3px);
    
        &::before {
            content: "";
            display: block;
            position: relative;
        }
    
        h2 {
            color: #3B65F1;
            font-weight: 550;
            line-height: .65rem;
        }
    
        p {
            font-size: $content_size;
            color: #fff;
            line-height: .46rem;
            font-weight: 300;
    
            a {
                color: #fff;
                text-decoration: none;
            }
        }
    }
    
    .staple {
        position: relative;
    
        &::after {
            content: "";
            display: block;
            background-color: #3B65F1;
            width: .24rem;
            height: .68rem;
            border-radius: .08rem;
            position: absolute;
            top: -.52rem;
            left: .8rem;
        }
    
        &::before {
            content: "";
            display: block;
            background-color: #3B65F1;
            width: .24rem;
            height: .68rem;
            border-radius: .08rem;
            position: absolute;
            top: -.52rem;
            right: .8rem;
        }
    }
}

.wenshujiao {
    .title {
        position: relative;
        height: .45rem;
        width: 2rem;
        background-image: url('~@images/wallet/ws_title.png');
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: .2rem;
        margin-bottom: .78rem;
    }

    .content {
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: .45rem;
            top: -.5rem;
            z-index: 2;
            width: $wenshu_title_size;
            height: $wenshu_title_size;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('~@images/wallet/ws_title_icon.png');
        }
        position: relative;
        color: #fff;
        line-height: .45rem;

        padding: .5rem .22rem .4rem;
        font-weight: 300;
        font-size: $content_size;

        border-radius: .15rem;
        border: #5C8ECA solid 1px;

        backdrop-filter: blur(4px);
        background: rgba($color: #171740, $alpha: 0.75);

        p {
            position: relative;
            z-index: 1;
        }
    }
}

.shuhe {
    position: relative;

    .title {
        height: .5rem;
        background-image: url('~@images/wallet/shuhe_title.png');
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 1.8rem;
        margin-bottom: .4rem;
    }

    .sub_img_wrapper {
        position: relative;
        background-color: #fff;
        height: 1.7rem;
        width: 100%;
        border-radius: .15rem;
        border: #3B65F1 solid 1px;
        margin-bottom: .33rem;

        .sub_img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 3.5rem;
            height: 1.3rem;
            background-image: url('~@images/wallet/shuhe_subimg.jpg');
            background-size: cover;
        }
    }
}

.personal_wallet {
    .title {
        position: relative;
        height: .5rem;
        background-image: url('~@images/wallet/pn_wallet_title.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: .5rem;
        margin-bottom: .8rem;
    }
}

.hualiu_meta {
    position: relative;

    .title {
        height: .48rem;
        background-image: url('~@images/wallet/hualiu_title.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: .5rem;
        margin-bottom: .8rem;
    }

    .subimg {
        width: 3rem;
        height: 1.2rem;
        background-image: url('~@images/wallet/hualiu_subimg.png');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 0;
        top: .6rem;
        z-index: 2;
    }
}

.cooperations {
    .title {
        height: .48rem;
        background-image: url('~@images/wallet/cooperations_title.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: .5rem;
        margin-bottom: .3rem;
    }

    .company {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: .7rem;

        .img_wrapper {
            width: 50%;
            box-sizing: border-box;
            padding: .23rem;

            .com_icon {
                width: 100%;
            }
        }
    }
}