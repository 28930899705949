.index_body {
  // min-height: 100vh;
  background-color: #171740;

  .banners {
    position: relative;

    .banner {
      width: 7.5rem;
      height: 9.08rem;
    }

    .banner-info {
      position: absolute;
      top: 0;
      left: 0;
      width: 7.5rem;

      .title {
        text-align: center;
        margin: 1.68rem 0;
        font-size: 0.72rem;
        font-weight: 800;
        color: #FFFFFF;
      }

      &.info2 {
        .title {
          margin-bottom: .5rem;
        }
        .content {
          line-height: .35rem;
        }
      }

      .content {
        font-weight: 700;
        position: relative;
        width: 6.38rem;
        margin: 0 auto;
        border-radius: 0.32rem;
        background-color: #ffffff3f;
        border: 0.02rem solid rgba(19, 32, 101, 0.91);
        backdrop-filter: blur(0.3rem);
        font-size: 0.24rem;
        line-height: .4rem;
        color: #FFFFFF;
        text-align: left;
        box-sizing: border-box;
        padding: .24rem;

        .banner-picture {
          display: block;
          position: absolute;
          top: -.57rem;
          right: .5rem;
          background-color: #ffffffc0;
          width: 1.14rem;
          height: 1.14rem;
          border-radius: 50%;
          border: 0.02rem solid #18256A;
        }
      }
    }

    // 分页圆点
    .swiper-pagination-bullet {
      width: 1.24rem;
      height: 0.1rem;
      background: rgba(255, 255, 255, 0.644);
      border-radius: 0.04rem;
      transition: all .4s;
    }

    // 选中分页圆点
    .swiper-pagination-bullet-active {
      background: #D8D8D8;
    }
  }

  .project-team {
    width: 7.5rem;
    height: 1.8rem;
    background: #171740;
    box-shadow: inset 14px -4px 58px 22px rgba(23, 23, 64, 0.87);
    display: flex;
    align-items: center;
    justify-content: center;

    .project-item {
      width: 2.8rem;
      height: 1.24rem;
      background: #0D0D2A;
      border-radius: 0.16rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .24rem;
      color: #fff;
      margin: 0 auto;

      img {
        width: .78rem;
        height: .78rem;
        border-radius: .16rem;
        margin-right: .2rem;
      }
    }



    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      z-index: 10;
      top: 50%;
      transition: all .3s;
      transform: translateY(-50%);

      img {
        width: .5rem;
        height: .5rem;
      }
    }

    .swiper-button-prev {
      left: .1rem;
    }

    .swiper-button-next {
      right: .1rem;
    }

    // 禁用切换按钮
    .swiper-btn-disabled {
      opacity: 0;
    }
  }

  .project-infomation {
    width: 7.5rem;
    padding: .6rem .4rem 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #FFF3C2;

    .project-item {
      width: 3.18rem;
      min-height: 4.54rem;
      background: #FFF8DC;
      border-radius: 0.16rem;
      border: 0.01rem solid #F1D76B;
      text-align: left;
      box-sizing: border-box;
      padding: .3rem .24rem;
      margin-bottom: .6rem;

      img {
        width: .68rem;
        height: .68rem;
        border-radius: .16rem;
        margin-bottom: .2rem;
      }

      .name {
        font-weight: bold;
        margin-bottom: .18rem;
        font-size: .36rem;
      }

      .infomation {
        font-size: .24rem;
        text-align: justify;
      }
    }
  }

  .collection {
    text-align: center;
    width: 7.5rem;
    height: 14.16rem;
    background: radial-gradient(rgba(50, 50, 167, 0.7) 0%, #171740 100%);
    overflow: hidden;
    color: #fff;

    .title {
      color: #FFF3C2;
      font-size: .72rem;
      font-weight: bold;
    }

    .swiper {
      padding: 1.36rem 0 0;

      .swiper-box {
        padding-bottom: 1.88rem;
      }

      .collection-item {
        position: relative;

        .collection-img {
          width: 4.56rem;
          height: 5.84rem;
        }

        .collection-btn {
          position: absolute;
          color: #fff;
          top: 70%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 1.84rem;
          height: 0.64rem;
          line-height: .64rem;
          text-align: center;
          font-size: .28rem;
          background: rgba(24, 23, 64, 0.425);
          border-radius: 0.16rem;
          backdrop-filter: blur(0.06rem);
          border: 0.02rem solid rgba(23, 23, 64, 0.5);
          transition: all .4s;
          text-decoration: none;
        }

        .callection-name{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 4rem;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          height: 0.64rem;
          line-height: .64rem;
          text-align: center;
          font-size: .36rem;
          color: #fff;
          font-weight: bold;
        }

        .collection-btn:active {
          background-color: #ffffff65;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        position: absolute;
        z-index: 10;
        top: 40%;
        transition: all .3s;
        transform: translateY(-50%);

        img {
          // width: .2rem;
          height: .66rem;
        }
      }

      .swiper-button-prev {
        left: .4rem;
      }

      .swiper-button-next {
        right: .4rem;
      }

      // 禁用切换按钮
      .swiper-btn-disabled {
        opacity: 0;
      }

      .swiper-pagination {
        bottom: 1rem;
      }

      // 分页圆点
      .swiper-pagination-bullet {
        width: .72rem;
        height: 0.08rem;
        background: rgba(255, 255, 255, 0.644);
        border-radius: 0.04rem;
        transition: all .4s;
      }

      // 选中分页圆点
      .swiper-pagination-bullet-active {
        background: #D8D8D8;
      }
    }

    .look-over {
      display: block;
      text-decoration: none;
      width: 3.9rem;
      height: 0.86rem;
      line-height: .86rem;
      background: #AF0D3B;
      font-size: .36rem;
      border-radius: 0.16rem;
      margin: 0 auto;
      transition: all .5s;
    }

    .look-over:active {
      background-color: #793d3d;
    }

  }

  .activitys {
    padding: 1rem .48rem 0;
    background: #FFF3C2;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: column;

    .text-dere{
      text-decoration: none !important;
      display: block;
    }

    .activity-item {
      // height: 3.7rem;
      border-radius: .24rem;
      background: #FFF8DC;
      border-radius: .24rem;
      margin-bottom: 1rem;

      .tweet-img {
        width: 6.54rem;
        height: 2.51rem;
      }

      .activity-content {
        box-sizing: border-box;
        padding: .24rem .2rem;
        display: flex;
        align-items: center;

        .content {
          color: #434141;
          font-size: .28rem;
          text-align: left;
        }

        .look {
          width: 1.26rem;
          height: .46rem;
          color: #fff;
          text-align: center;
          line-height: .46rem;
          background-color: #AF0D3B;
          border-radius: .12rem;
          margin-left: .12rem;
        }
      }
    }
  }

  .title-tip {
    text-align: center;
    width: 2.7rem;
    height: 0.84rem;
    line-height: .84rem;
    background: #ffffff07;
    border-radius: 0.16rem;
    margin: .88rem auto .64rem;
    font-size: .36rem;
    color: #fff;
  }

  .members {
    .title-tip {
      margin: 1rem auto;
    }

    .member-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 .48rem;

      .member-item {
        margin-bottom: .56rem;
        width: 3.08rem;
        height: 1.84rem;
        background: #23234A;
        border-radius: 0.24rem;
        display: flex;
        box-sizing: border-box;
        padding: 0 .3rem;
        align-items: center;
        font-size: .32rem;
        color: #fff;

        img {
          width: 1.04rem;
          height: 1.04rem;
          border-radius: 0.28rem;
          margin-right: .24rem;
        }
      }
    }
  }
}