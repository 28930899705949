$item_img_w: 1.5rem;
$item_img_p: .25rem;
$item_img_m: .15rem;
$item_interval: 15s;

.topbar {
    box-sizing: border-box;
    position: relative;
    height: 10rem;
    background: url("~@images/diy/main_title.png") no-repeat;
    background-size: cover;
    padding-bottom: -.2rem;

    img {
        width: 100%;
    }

    .bar_btns {
        box-sizing: border-box;
        width: 100%;
        position: absolute;
        bottom: 2.3rem;
        display: flex;
        padding: 0 .45rem;

        div {
            flex: 1;
            height: .9rem;
            margin: .25rem;
            border-radius: .8rem;
            box-sizing: border-box;
            padding: .3rem .7rem;
        }

        .dao {
            background-color: rgba($color: #FFF3C2, $alpha: .5);
        }

        .diy {
            background-color: #B21944;
        }
    }
}

.function {
    box-sizing: border-box;
    padding-top: .6rem;
    background: url("~@images/diy/bg.png") center;
    background-size: cover;
    background-position-y: -8.5rem;

    .wrapper {
        box-sizing: border-box;
        width: 6.2rem;
        margin: auto;
        background: url("~@images/dao/card.png") no-repeat center;
        background-size: contain;
        padding: .35rem 1.15rem .35rem .4rem;

        &>h1 {
            text-transform: uppercase;
            font-size: .6rem;
            color: #fff;
            padding: .1rem 0;
            margin-left: .5rem;
        }

        &>h2 {
            font-weight: 900;
            font-size: .3rem;
            padding: .1rem .15rem;
            color: #B21944;
            border: #B21944 solid 2px;
            border-radius: .7rem;
            display: inline-block;
            margin-left: 1.1rem;
            margin-bottom: .2rem;
        }

        &>p {
            color: #FFF3C2;
            line-height: .34rem;
            margin-bottom: .35rem;
        }

        .card {
            box-sizing: border-box;
            padding: .15rem;
            background-color: #F7ECBB;
            border-radius: .25rem;

            img {
                width: 100%;
            }

            h2 {
                color: #B21944;
                padding: .15rem 0;
            }

            .content {
                display: flex;
                padding-bottom: .15rem;

                p {
                    flex: 3;
                    color: #B21944;
                }

                a {
                    color: #B21944;
                    flex: 1;
                    border: #B21944 1px solid;
                    border-radius: .2rem;
                    background: transparent;
                    text-decoration: none;
                    text-align: center;
                }
            }
        }
    }

    :global(.title) {
        padding: 1rem 1.5rem;
    }

    .preview {
        position: relative;
        display: flex;
        box-sizing: border-box;
        padding: 0 .3rem;

        img {
            width: 2.5rem;
        }

        .leftitem,
        .rightitem {
            flex: 1;
            border: #231D81 1px solid;
            border-radius: .2rem;
            background-color: #1C1944;
            padding: .7rem 0;
            opacity: .8;
            overflow: hidden;
        }

        .rightitem {
            padding-left: 2rem;
        }

        .mainitem {
            position: absolute;
            top: -.2rem;
            left: 2.2rem;
            width: 3rem;
            border-radius: .2rem;
            border: #231D81 solid 1px;
            background-color: #221F55;
            padding: .55rem 0;

            img {
                width: 3.2rem;
            }
        }
    }

    .random_btn {
        text-align: center;
        padding: .75rem 0;

        img {
            width: 2.2rem;
        }
    }
}

.display {
    position: relative;
    overflow: hidden;

    .shadow {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 9px -7px 20px 14px #121234,
         inset -5px -6px 20px 8px #121234;
    }
    img {
        background-color: #C1C1C1;
        width: $item_img_w;
        padding: 0 $item_img_p;
        margin: 0 $item_img_m;
    }

    .rows {
        display: flex;
        padding-bottom: .3rem;

        &.left {
            animation: left_scroll $item_interval linear infinite;
        }
        &.right {
            animation: right_scroll $item_interval linear infinite;
        }
    }

    @keyframes left_scroll {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-(4 * (2*$item_img_m+2*$item_img_p+$item_img_w)));
        }
    }
    @keyframes right_scroll {
        0% {
            transform: translateX(-(4 * (2*$item_img_m+2*$item_img_p+$item_img_w)));
        }

        100% {
            transform: translateX(0);
        }
    }
}