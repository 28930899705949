.topbar {
    box-sizing: border-box;
    position: relative;
    height: 10rem;
    background: url("~@images/dao/title_bg.png") no-repeat center;
    background-size: cover;

    img {
        width: 100%;
    }

    .main_title {
        padding: 3.2rem .8rem;
    }

    .bar_btns {
        box-sizing: border-box;
        width: 100%;
        position: absolute;
        bottom: 2.3rem;
        display: flex;
        padding: 0 .45rem;

        div {
            flex: 1;
            height: .9rem;
            margin: .25rem;
            border-radius: .8rem;
            box-sizing: border-box;
            padding: .3rem .7rem;
        }

        .dao {
            background-color: #B21944;
        }

        .diy {
            background-color: rgba($color: #FFF3C2, $alpha: .5);
        }
    }
}

.introduction {
    box-sizing: border-box;
    padding-top: .6rem;
    height: 10.3rem;
    background-color: #F7ECBB;

    .wrapper {
        box-sizing: border-box;
        width: 6.2rem;
        margin: auto;
        background: url("~@images/dao/card.png") no-repeat center;
        background-size: contain;
        padding: .35rem 1.15rem .35rem .4rem;

        &>h1 {
            text-transform: uppercase;
            font-size: .4rem;
            color: #fff;
            padding: .3rem 0;
        }

        &>h2 {
            font-weight: 900;
            font-size: .3rem;
            padding: .1rem .15rem;
            color: #B21944;
            border: #B21944 solid 2px;
            border-radius: .7rem;
            display: inline-block;
            margin-left: 1.1rem;
            margin-bottom: .2rem;
        }

        &>p {
            color: #FFF3C2;
            line-height: .34rem;
            margin-bottom: .35rem;
        }

        .card {
            box-sizing: border-box;
            padding: .15rem;
            background-color: #F7ECBB;
            border-radius: .25rem;

            img {
                width: 100%;
            }

            h2 {
                color: #B21944;
                padding: .15rem 0;
            }

            .content {
                display: flex;
                padding-bottom: .15rem;

                p {
                    flex: 3;
                    color: #B21944;
                }

                a {
                    color: #B21944;
                    flex: 1;
                    border: #B21944 1px solid;
                    border-radius: .2rem;
                    background: transparent;
                    text-decoration: none;
                    text-align: center;
                }
            }
        }
    }
}

.module {
    .img100 {
        width: 100%;
    }

    .title {
        width: 6.2rem;
        margin: auto;
        padding: 1.15rem 0;
    }

    .collection_item {
        position: relative;
        width: 5rem;
        height: 5.5rem;
        margin: auto;

        .content {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            color: #fff;
            box-sizing: border-box;
            text-align: center;

            * {
                padding: .3rem .3rem
            }

            p {
                line-height: .4rem;
                margin-bottom: .45rem;
            }
        }
    }

    .swiper_box {
        .collection_btn {
            text-align: center;
            position: relative;
            color: #fff;
            background-color: #DC2626;
            margin: auto;
            width: 2.2rem;
            line-height: .8rem;
            border-radius: .15rem;
            margin-top: .3rem;
            margin-bottom: 1rem;
        }

        // 分页圆点
        :global(.swiper-pagination-bullet) {
            width: .5rem;
            height: 0.1rem;
            background: #F7ECBB;
            border-radius: 0.04rem;
            transition: all .4s;
        }

        // 选中分页圆点
        :global(.swiper-pagination-bullet-active) {
            background: #DC2626;
        }
        :global([class *= 'swiper-button-']){
            position: absolute;
            z-index: 10;
            top: 40%;
            transition: all .3s;
            transform: translateY(-50%);
      
            img{
              width: .66rem;
            }
          }
      
          :global(.swiper-button-prev){
            left: .3rem;
          }
      
          :global(.swiper-button-next){
            right: .3rem;
          }

          :global(.swiper-btn-disabled) {
            opacity: 0;
          }
    }




}