.ipproject {
    padding: 0 .6rem .6rem;
    box-sizing: border-box;
    background-image: url("~@images/ipproject/bg.png"), url("~@images/ipproject/bg2.png");
    background-position: center 0;
    background-size: contain;
    background-repeat: no-repeat, repeat-y;

    .main_titile {
        padding: 2.6rem 0 2rem;
        margin-bottom: .3rem;

        img {
            width: 100%;
            height: auto;
            margin-bottom: .7rem;
        }

        p {
            font-size: .28rem;
            color: #fff;
            line-height: .5rem;
        }
    }

    .project_item {
        border-color: rgba(27, 93, 115, .39);
        border-style: solid;
        border-width: .4rem .3rem;
        border-radius: .37rem;
        margin-bottom: .65rem;

        .border_wrapper {
            margin: -.13rem -.1rem;
            border: 1px solid transparent;
            border-radius: .37rem;
            height: 5.5rem;

            position: relative;
            overflow: hidden;

            img,video {
                margin-top: -.3rem;
                width: 100%;
            }
            video {
                margin-top: .05rem;
            }

            .title {
                box-sizing: border-box;
                padding: .2rem;
                width: 100%;
                line-height: .75rem;
                background-color: rgba($color: #464646, $alpha: .8);
                backdrop-filter: blur(5px);
                position: absolute;
                bottom: 0;
                left: 0;

                h2 {
                    color: #fff;
                    font-weight: 500;
                    margin-bottom: .1rem;
                }

                div {
                    padding: .15rem;

                    button {
                        font-size: .25rem;
                        background-color: #282F8A;
                        border: none;
                        color: #fff;
                        padding: .15rem .25rem;
                        border-radius: .1rem;
                        float: right;
                    }

                    &::after {
                        content: "";
                        display: block;
                        clear: both;
                    }
                }
            }
        }
    }
}