$navigator_height: 1.04rem;

.meta_body {
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #171740;

  .mask {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
    transition: all .5s;
    background-color: rgba($color: #000000, $alpha: .6);
  }

  .header_wrapper {
    height: $navigator_height;
    .head {
      position: fixed;
      z-index: 10;
      width: 100vw;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: .16rem 0.38rem;
      height: $navigator_height;
      background-color: #fff;
      border-top: 0.08rem solid #AF0D3B;
      transition: all .4s;

      &.close {
        opacity: 0;
      }

      .logo {
        height: 0.64rem;
      }

      .sidebar {
        height: .3rem;
      }
    }

    .aside {
      position: fixed;
      z-index: 10;

      width: 100vw;
      top: 0;
      left: 0;
      background-color: #FFF8DC;
      color: #3D3D3D;
      transition: all .5s;
      overflow: hidden;

      .head {
        position: relative;
        background-color: #FFF8DC;
      }

      .aside-list {
        font-weight: 500;
        text-align: left;
        padding: 0 .38rem .24rem;

        .navbar {
          font-size: 0.36rem;
          line-height: .75rem;
          // padding-bottom: .1rem;

          .secondary-navbar {
            padding-left: .4rem;
            font-size: 0.29rem;
            line-height: .65rem;
          }
        }
      }
    }

    .aside-close {
      opacity: 0;
      height: 0;
    }
  }

  .footer {
    position: relative;
    padding: .44rem;
    color: #fff;
    text-align: left;

    .footer-logo {
      display: block;
      margin: 0 auto;
      width: 3.2rem;
    }

    .contact-tip {
      font-size: .28rem;
      margin: 1rem 0 .3rem;
    }

    .contact-text {
      font-size: .24rem;
      margin: 0 0 .3rem;
    }

    .qrcodes {
      margin: .3rem 0;

      img {
        width: 1.06rem;
        height: 1.06rem;
        margin-right: .48rem;
      }
    }

    .footer-content {
      font-size: .24rem;
      margin-bottom: .3rem;

      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}