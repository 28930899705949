.hualiumeta_body {
    height: 15.2rem;
    background: url("~@images/hualiumeta/bg.png") no-repeat center;
    background-size: cover;
    position: relative;
    box-sizing: border-box;

    .content {
        font-weight: 300;
        font-size: .26rem;
        line-height: .55rem;
        margin: 0 .35rem;
        padding: .4rem .25rem;
        border-radius: .15rem;

        position: absolute;
        top: 7rem;

        color: #eee;
        border: #EDABA0 solid 1px;
        background-color: rgba($color:#171740, $alpha:.8);
    }
}